import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { envLanguageDetector } from './envLanguageDetector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(envLanguageDetector);

export const supportedLanguages = {
  en: 'English',
  ru: 'Русский',
  kk: 'Қазақша',
  sr: 'Српски',
  es: 'Español',
};

export type SupportedLanguages = keyof typeof supportedLanguages;

use(initReactI18next)
  .use(languageDetector)
  .init({
    /* Fallback locale */
    fallbackLng: 'en',
    defaultNS: 'web',
    supportedLngs: Object.keys(supportedLanguages),
    debug: true,
    /** resources are loaded asynchronously
     * @see useSetupTranslations.ts
     */
    resources: {},
    interpolation: {
      escapeValue: false,
      prefix: '[[',
      suffix: ']]',
    },
    detection: {
      order: [
        'cookie',
        'localStorage',
        'sessionStorage',
        envLanguageDetector.name,
      ],
    },
  });

export default i18next;
