import { useState } from 'react';
import { Col, Input, Popover, Row, Select } from 'antd';
import { FilterTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { TagsFilterProps } from './types';

const { Search } = Input;

export const TagsFilter = ({
  filterState,
  dispatch,
  hashtags,
}: TagsFilterProps) => {
  const { t } = useTranslation();

  const [tagValue, setTagValue] = useState(filterState.tag);

  return (
    <div className={styles.container}>
      <Row
        gutter={16}
        style={{ marginBottom: 8 }}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Search
            placeholder={t('rfid_tags.filter.search-tag')}
            value={tagValue}
            onChange={(e) => setTagValue(e.target.value)}
            onSearch={(value) =>
              dispatch({ type: 'SELECT_TAG', payload: { tag: value } })
            }
            allowClear
            enterButton
          />
        </Col>
        <Col>
          <Popover
            placement={'leftTop'}
            content={
              <>
                {hashtags && (
                  <Select
                    allowClear
                    mode="multiple"
                    style={{ width: '250px' }}
                    placeholder={t('rfid_tags.filter.select-groups')}
                    value={filterState.hashTags}
                    onChange={(value) =>
                      dispatch({
                        type: 'SELECT_HASH_TAGS',
                        payload: { hashTags: value },
                      })
                    }
                    options={hashtags.map((hashtag) => ({
                      label: hashtag,
                      value: hashtag,
                    }))}
                  />
                )}
              </>
            }
            trigger="click"
          >
            <FilterTwoTone style={{ fontSize: '20px' }} />
          </Popover>
        </Col>
      </Row>
    </div>
  );
};
