import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { message, Spin, App } from 'antd';
import { usePartnerId } from 'core/providers/PartnerProvider';
import {
  USE_CHARGE_POINT_QUERY_KEY,
  useChargePointsUpdateMutation,
} from 'features/charge-points/queries';
import { useLocationQueryById } from 'features/locations/queries';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { LocationAccordion } from 'pages/Locations/components/LocationAccordion';
import { LocationsSearchInput } from 'pages/Locations/components/LocationsSearchInput';

const LocationTab = () => {
  const partnerId = usePartnerId();
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const { chargePoint } = useChargePointsDetailsContext();
  const [messageApi, contextHandler] = message.useMessage();
  const { locationId } = chargePoint;
  const queryClient = useQueryClient();
  const { modal } = App.useApp();

  // common loading state for query and mutation
  const [isLoading, setIsLoading] = useState(false);

  const chargePointMutation = useChargePointsUpdateMutation();

  const {
    data: location,
    error,
    isLoading: isLoadingLocation,
  } = useLocationQueryById(partnerId, locationId, {
    enabled: !!locationId,
  });

  const onLocationSelection = (locationId: string) => {
    modal.confirm({
      title: t('locations.titles.change-location'),
      content: t('locations.text.confirm-change-location'),
      onOk: () => {
        chargePointMutation
          .mutateAsync({
            partnerId,
            id: chargePoint.id,
            locationId,
          })
          .then((data) => {
            queryClient.setQueryData(
              [USE_CHARGE_POINT_QUERY_KEY, chargePoint.id],
              data,
            );
          });
      },
    });
  };

  useEffect(() => {
    setIsLoading(isLoadingLocation || chargePointMutation.isLoading);
  }, [isLoadingLocation, chargePointMutation.isLoading]);

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: errorT(error?.translationKey, error.message),
      });
    }
  }, [error, messageApi, errorT]);

  return (
    <>
      {contextHandler}
      <LocationsSearchInput
        onLocationSelection={onLocationSelection}
        style={{ marginBottom: 16 }}
      />
      {isLoading && <Spin />}
      {!isLoading && location && <LocationAccordion location={location} />}
    </>
  );
};

export default LocationTab;
