import { useMemo } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash-es';

import styles from './styles.module.css';
import { LocationTextSearchProps } from './types';

const { Search } = Input;

export const LocationsTextSearch = ({
  onSearch,
  isLoading,
}: LocationTextSearchProps) => {
  const { t } = useTranslation();

  const debouncedTextSearchUpdate = useMemo(
    () =>
      debounce((textSearch: string) => {
        onSearch(textSearch);
      }, 1000),
    [onSearch],
  );

  const onTextSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debouncedTextSearchUpdate(event.target.value);
  };

  const onSearchSubmit = (value: string) => {
    onSearch(value);
  };

  return (
    <div className={styles.input}>
      <Search
        onChange={onTextSearchInputChange}
        enterButton={t('common.actions.search')}
        placeholder={t('locations.placeholders.search-text')}
        onSearch={onSearchSubmit}
        allowClear
        loading={isLoading}
        style={{ width: '50%', minWidth: '300px' }}
      />
    </div>
  );
};
