import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LocationPlace } from 'features/locations/types';

import type { LocationPlacesColumnsProps } from './types';

export const useLocationPlacesColumns = ({
  onUpdate,
  onDelete,
}: LocationPlacesColumnsProps): ColumnsType<
  LocationPlace & { key: string }
> => {
  const { t } = useTranslation();

  return [
    {
      title: t('locations.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('locations.columns.address'),
      dataIndex: 'address',
      key: 'address',
      width: '40vw',
    },
    {
      title: t('locations.columns.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('locations.columns.actions'),
      render: (_: unknown, place, index) => (
        <Row style={{ gap: '8px' }}>
          <Button type="default" onClick={() => onUpdate(index)}>
            {t('common.actions.update')}
          </Button>
          <Popconfirm
            title={
              <span
                dangerouslySetInnerHTML={{
                  __html: t('locations.text.confirm-delete-place', {
                    placeName: `<b>${place.name}</b>`,
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            }
            okText={t('locations.text.yes')}
            cancelText={t('locations.text.no')}
            onConfirm={() => onDelete(index)}
          >
            <Button>{t('common.actions.delete')}</Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];
};
