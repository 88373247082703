import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import type { LocationsSearchQueryResponse } from '../types';

export async function fetchSearchLocations(
  partnerId: string,
  fts: string,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response =
      await Services.apiService.get<LocationsSearchQueryResponse>(
        `partners/${partnerId}/charging/locations/search/light/query`,
        {
          params: { fts },
          headers: { ...header },
        },
      );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
