import { Button, Typography } from 'antd';
import { Padding } from 'components/Padding';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LocationsTable } from './components/LocationsTable';

export const Locations = () => {
  const { t } = useTranslation();

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('locations.titles.locations')}
        </Typography.Title>
        <Link to="/locations/create">
          <Button type={'primary'}>{t('common.actions.create')}</Button>
        </Link>
      </Padding>
      <Padding>
        <LocationsTable />
      </Padding>
    </>
  );
};
