import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { fetchLocationCreate } from '../fetches';
import type { Location } from '../types';

export function useLocationCreateMutation(
  options?: UseMutationOptions<
    Location,
    Types.HttpError,
    Parameters<typeof fetchLocationCreate>
  >,
) {
  return useMutation((args) => fetchLocationCreate(...args), {
    retry: 0,
    ...options,
  });
}
