import * as Services from 'core/services';
import * as Types from 'core/types';
import { AuthHelpers } from 'features/auth';

import type { LocationsQueryResponse, LocationsSearchParams } from '../types';

export async function fetchLocations(
  partnerId: string,
  params?: LocationsSearchParams,
) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.get<LocationsQueryResponse>(
      `partners/${partnerId}/charging/locations/search/query`,
      {
        params: { ...params },
        headers: { ...header },
      },
    );
    
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as Types.HttpError);
  }
}
