import { Typography } from 'antd';
import { Padding } from 'components/Padding';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useChargePointsPartnerItemsQuery } from 'features/charge-points/queries';
import { TransactionsTable } from 'views/Transactions';
import { useTransactionTableColumns } from 'views/Transactions/useTransactionsTableColumns';

import { useTransactionsFilter } from './TransactionsFilter/useTransactionsFilter';
import { TransactionsFilter } from './TransactionsFilter';

dayjs.extend(utc);

export function Transactions() {
  const { t } = useTranslation();

  const transactionTableColumns = useTransactionTableColumns();

  const [filterState, dispatch] = useTransactionsFilter();

  const partnerId = usePartnerId();

  const chargePointsPartnerItemsQuery = useChargePointsPartnerItemsQuery(
    partnerId,
    { withAvailability: false },
    { index: 1, size: 999999 },
  );

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('transactions.titles.transactions')}
        </Typography.Title>
      </Padding>

      <Padding>
        <TransactionsFilter
          filterState={filterState}
          dispatch={dispatch}
          chargePoints={chargePointsPartnerItemsQuery.data?.items ?? []}
        />
      </Padding>

      <Padding noStart>
        <TransactionsTable
          partnerId={partnerId}
          tableColumns={transactionTableColumns}
          chargePoints={chargePointsPartnerItemsQuery.data?.items ?? []}
          filter={filterState}
        />
      </Padding>
    </>
  );
}
