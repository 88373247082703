import { Empty, Spin, Typography } from 'antd';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useLocationQueryById } from 'features/locations/queries';
import { LocationAccordion } from 'pages/Locations/components/LocationAccordion';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const LocationEdit = () => {
  const partnerId = usePartnerId();
  const { locationId = '' } = useParams<{ locationId: string }>();

  const { data: location, isLoading } = useLocationQueryById(
    partnerId,
    locationId,
  );

  const { t } = useTranslation();

  if (isLoading) {
    return <Spin />;
  }

  if (!location) {
    return <Empty />;
  }

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('locations.titles.update-location')} – {location.name}
        </Typography.Title>
      </Padding>
      <Padding>
        <LocationAccordion location={location} />
      </Padding>
    </>
  );
};
