import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Modal, Spin, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { usePartnerFileUploadMutation } from 'features/files/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  getFileExtension,
  getImageBase64,
  getImageDimensions,
  randString,
} from 'core/utils';
import { fetchPartnerFileLink } from 'features/files/fetches';
import { ExtendedUploadFile } from 'pages/ChargePointsDetails/tabs/ImagesTab/types';
import { HttpError } from 'core/types';

import { PlaceImagesProps } from './types';

const MAX_IMAGES = 5;

export const LocationPlaceImages = forwardRef(
  (
    { locationId, placeImages, onImageDelete, onImageUpload }: PlaceImagesProps,
    ref,
  ) => {
    const [messageApi, contextHolder] = message.useMessage();
    const partnerId = usePartnerId();
    const upload = usePartnerFileUploadMutation();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<ExtendedUploadFile[]>([]);
    const [init, setInit] = useState(false);
    const [error, setError] = useState(false);

    const { t } = useTranslation();
    const { t: errorT } = useErrorTranslation();

    const isSubpartner = useIsSubpartner();

    useImperativeHandle(ref, () => ({
      resetState() {
        setFileList([]);
      },
    }));

    const handlePreviewClose = () => setPreviewOpen(false);

    const handlePreviewOpen = async (file: ExtendedUploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getImageBase64(file.originFileObj as RcFile);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      );
    };

    const handleRemove = async (file: ExtendedUploadFile) => {
      const fileListBeforeRemove = [...fileList];

      try {
        setFileList((fileList) => fileList.filter((f) => f.uid !== file.uid));
        onImageDelete(file.customProps);
      } catch (e) {
        setFileList(fileListBeforeRemove);
        messageApi.open({
          type: 'error',
          content: t('chargepoints.errors.remove-error'),
        });
      }
    };

    const handleUpload = async ({ file }: UploadRequestOption) => {
      if (!(file instanceof File)) {
        messageApi.open({
          type: 'error',
          content: t('chargepoints.errors.upload-error'),
        });
        return;
      }

      const extension = getFileExtension(file.name);
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (file.size > maxSize) {
        message.open({
          type: 'error',
          content: t('chargepoints.errors.upload-file-size-error', {
            limit: maxSize,
          }),
        });
        return;
      }

      const uid = randString();

      try {
        const formData = new FormData();
        formData.append('image', file);

        setFileList((fileList) => [
          ...fileList,
          { uid, url: '', status: 'uploading' as 'uploading', name: file.name },
        ]);
        const dimensions = await getImageDimensions(file);
        const { id: fileId } = await upload.mutateAsync([
          partnerId,
          formData,
          {
            bucketName: 'img',
            path: `locations/${locationId}/places`,
            extension,
          },
        ]);
        const fileUrl = await fetchPartnerFileLink(partnerId, fileId);
        onImageUpload({
          fileId,
          url: fileUrl,
          width: dimensions.width,
          height: dimensions.height,
          type: extension.replace('jpg', 'jpeg'),
          category: 'location',
        });

        setFileList((fileList) =>
          fileList.map((file) =>
            file.uid === uid
              ? {
                  ...file,
                  status: 'done',
                  url: fileUrl,
                  customProps: { ...file.customProps, originalFileId: fileId },
                }
              : file,
          ),
        );
      } catch (error) {
        setFileList((fileList) => fileList.filter((file) => file.uid !== uid));

        if ((error as HttpError).translationKey) {
          messageApi.open({
            type: 'error',
            content: errorT((error as HttpError).translationKey),
          });
        } else {
          messageApi.open({
            type: 'error',
            content: t('chargepoints.errors.upload-error'),
          });
        }
      }
    };

    useEffect(() => {
      const prepareImages = async () => {
        try {
          const images = placeImages ?? [];
          const newImages = [];
          for (const image of images) {
            let url =
              image.url ??
              (await fetchPartnerFileLink(partnerId, image.fileId));

            newImages.push({
              uid: randString(),
              name: '',
              url,
              customProps: {
                originalFileId: image.fileId,
                originalFileUrl: image.url,
              },
            });
          }

          setFileList(newImages);
        } catch (e) {
          setError(true);
        } finally {
          setInit(true);
        }
      };

      prepareImages();
    }, [partnerId, locationId, placeImages]);

    if (!init) {
      return <Spin />;
    }

    if (error) {
      return <div>{t('common.status.failed')}</div>;
    }

    return (
      <>
        {contextHolder}
        <Upload
          listType="picture-card"
          fileList={fileList}
          maxCount={1}
          onPreview={handlePreviewOpen}
          onRemove={handleRemove}
          customRequest={handleUpload}
          accept=".png,.jpg,.gif,.svg"
          disabled={isSubpartner}
        >
          {fileList.length >= MAX_IMAGES ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {t('chargepoints.text.upload')}
              </div>
            </div>
          )}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handlePreviewClose}
        >
          <img alt="" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  },
);
