import { Collapse } from 'antd';
import { useChargePointsPartnerItemsQuery } from 'features/charge-points/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';

import type { LocationAccordionProps } from './types';
import { useAccordionItems } from './useAccordionItems';

export const LocationAccordion = ({ location }: LocationAccordionProps) => {
  const partnerId = usePartnerId();
  const { data } = useChargePointsPartnerItemsQuery(partnerId, {
    withAvailability: true,
    withEmergencyButtonTime: true,
    withLastConnectionTime: true,
    locationId: location.id,
  });

  const chargePoints = data?.items ?? [];

  const accordionItems = useAccordionItems({ location, chargePoints });

  return (
    <Collapse
      items={accordionItems}
      defaultActiveKey={['1']}
    />
  );
};
