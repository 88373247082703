import { useState } from 'react';
import {
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Popover,
  Select,
  Tag,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { FilterTwoTone } from '@ant-design/icons';
import debounce from 'lodash-es/debounce';
import { useTranslation } from 'react-i18next';
import { ConnectorType } from 'features/charge-points/types';
import { connectorsLabels } from 'components/view/ConnectorLabel/connectors-labels.const';

import {
  AMOUNT_FROM,
  AMOUNT_TO,
  AmountFormValues,
  TransactionStatus,
  TransactionStatusGroup,
  TransactionsFilterProps,
} from './types';
import {
  mapTransactionStatusToTranslationKey,
  mapTransactionTagToTranslationKey,
} from './helpers';
import styles from './styles.module.css';

const { Search } = Input;
const { RangePicker } = DatePicker;

export const TransactionsFilter = ({
  filterState,
  dispatch,
  chargePoints,
}: TransactionsFilterProps) => {
  const { t } = useTranslation();

  const [amountForm] = Form.useForm();
  const [deviceTransactionId, setDeviceTransactionId] = useState(
    filterState.deviceTransactionId,
  );

  const onDateRangeChange: RangePickerProps['onChange'] = (value) => {
    if (!value) {
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: { startDateFrom: '', startDateTo: '' },
      });
      return;
    }

    const [dateFrom, dateTo] = value;

    if (!dateFrom || !dateTo) {
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: { startDateFrom: '', startDateTo: '' },
      });
      return;
    }

    const startDateFrom = dateFrom.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    const startDateTo = dateTo.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { startDateFrom, startDateTo },
    });
  };

  const debouncedAmountUpdate = debounce((amountValues: AmountFormValues) => {
    dispatch({
      type: 'UPDATE_AMOUNT',
      payload: amountValues,
    });
  }, 1000);

  const onAmountInputChange = () => {
    amountForm
      .validateFields()
      .then((formValues: AmountFormValues) => {
        debouncedAmountUpdate(formValues);
      })
      .catch(() => {
        debouncedAmountUpdate.cancel();
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Search
          placeholder={t('transactions.filter.device-transaction-id')}
          value={deviceTransactionId}
          onChange={(e) => {
            setDeviceTransactionId(e.target.value);
          }}
          onSearch={(value) => {
            dispatch({
              type: 'SELECT_DEVICE_TRANSACTION_ID',
              payload: { deviceTransactionId: value },
            });
          }}
          style={{ width: '300px' }}
          allowClear
          enterButton
        />
        <Form layout="vertical" form={amountForm} className={styles.amountForm}>
          <Form.Item
            className={styles.input}
            name={AMOUNT_FROM}
            rules={[
              {
                min: 0,
                type: 'number',
                message: t('transactions.rules.amount-non-negative'),
              },
            ]}
          >
            <InputNumber
              placeholder={t('transactions.filter.amount-from')}
              min={0}
              onChange={onAmountInputChange}
              style={{ minWidth: '120px' }}
            />
          </Form.Item>
          <Form.Item
            className={styles.input}
            name={AMOUNT_TO}
            rules={[
              {
                min: 1,
                type: 'number',
                message: t('transactions.rules.amount-greater-zero'),
              },
              {
                validator: (_, value) => {
                  const amountFrom = amountForm.getFieldValue('amountFrom');
                  if (value && amountFrom && value <= amountFrom) {
                    return Promise.reject(
                      t('transactions.rules.amount-to-higher-from'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              placeholder={t('transactions.filter.amount-to')}
              min={1}
              onChange={onAmountInputChange}
              style={{ minWidth: '120px' }}
            />
          </Form.Item>
        </Form>
        <div className={styles.dateInput}>
          <RangePicker
            format={'DD/MM/YYYY'}
            showTime={{ format: 'HH:mm' }}
            placeholder={[
              t('transactions.filter.started-from'),
              t('transactions.filter.started-to'),
            ]}
            onChange={onDateRangeChange}
          />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Popover
            placement={'leftTop'}
            content={
              <>
                <div className={styles.select}>
                  <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    placeholder={t('transactions.filter.select-statuses')}
                    maxTagCount="responsive"
                    value={filterState.statuses}
                    onChange={(value) => {
                      dispatch({
                        type: 'SELECT_STATUS',
                        payload: { statuses: value },
                      });
                    }}
                    options={Object.values(TransactionStatus).map((status) => ({
                      label: t(
                        mapTransactionStatusToTranslationKey[
                          status as TransactionStatus
                        ],
                      ),
                      value: status,
                    }))}
                  />
                </div>
                <div className={styles.select}>
                  <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    placeholder={t('transactions.filter.connector-types')}
                    maxTagCount="responsive"
                    value={filterState.connectorTypes}
                    onChange={(value) => {
                      dispatch({
                        type: 'SELECT_CONNECTOR_TYPE',
                        payload: { connectorTypes: value },
                      });
                    }}
                    options={Object.keys(connectorsLabels).map((connector) => ({
                      label: connectorsLabels[connector as ConnectorType],
                      value: connector,
                    }))}
                  />
                </div>
                <div className={styles.select}>
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    placeholder={t('transactions.filter.chargepoint-names')}
                    value={filterState.chargePointId}
                    onChange={(value) => {
                      dispatch({
                        type: 'SELECT_CHARGE_POINT_ID',
                        payload: { chargePointId: value },
                      });
                    }}
                    options={chargePoints?.map((chargePoint) => ({
                      label: chargePoint.name,
                      value: chargePoint.id,
                    }))}
                  />
                </div>
              </>
            }
            trigger="click"
          >
            <FilterTwoTone style={{ fontSize: '20px' }} />
          </Popover>
        </div>
      </div>
      <div className={styles.row}>
        <Tag.CheckableTag
          className={styles.tag}
          key={'not-free'}
          checked={filterState.hideZeroAmounts}
          onChange={() => dispatch({ type: 'HIDE_ZERO_AMOUNTS' })}
        >
          {t('transactions.filter.not-free')}
        </Tag.CheckableTag>
        {Object.keys(filterState.statusGroups).map((statusGroup) => {
          return (
            <Tag.CheckableTag
              className={styles.tag}
              key={statusGroup}
              checked={
                filterState.statusGroups[statusGroup as TransactionStatusGroup]
              }
              onChange={(checked) =>
                dispatch({
                  type: 'SELECT_STATUS_GROUP',
                  payload: {
                    statusGroup: statusGroup as TransactionStatusGroup,
                    isSelected: checked,
                  },
                })
              }
            >
              {t(
                mapTransactionTagToTranslationKey[
                  statusGroup as TransactionStatusGroup
                ],
              )}
            </Tag.CheckableTag>
          );
        })}
      </div>
    </div>
  );
};
