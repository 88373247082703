import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const useLocationsTableColumns = (): ColumnsType<
  Record<string, any>
> => {
  const { t } = useTranslation();

  return [
    {
      title: t('locations.columns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string, location: any) => (
        <Link to={`/locations/${location.id}`}>{name}</Link>
      ),
    },
    {
      title: t('locations.columns.country'),
      dataIndex: 'countryCode',
      key: 'countryCode',
    },
    { title: t('locations.columns.city'), dataIndex: 'city', key: 'city' },
    {
      title: t('locations.columns.address'),
      dataIndex: 'address',
      key: 'address',
      width: '40vw',
    },
    {
      title: t('locations.columns.timezone'),
      dataIndex: 'timeZone',
      key: 'timeZone',
    },
  ];
};
