import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteProps,
  Input,
  InputRef,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash-es';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useLocationsSearchQuery } from 'features/locations/queries';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import styles from './style.module.css';
import { LocationsSearchInputProps } from './types';

const { Search } = Input;

export const LocationsSearchInput = ({
  onLocationSelection,
  style = {},
}: LocationsSearchInputProps) => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const partnerId = usePartnerId();
  const [messageApi, contextHandler] = message.useMessage();

  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<AutoCompleteProps['options']>([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const { refetch, isFetching, error } = useLocationsSearchQuery(
    partnerId,
    searchValue,
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: errorT(error?.translationKey, error.message),
      });
    }
  }, [error, messageApi, errorT]);

  const fetchAutoCompleteOptions = useCallback(
    (searchValue: string) => {
      if (searchValue.length < 3) {
        return;
      }

      refetch().then(({ data }) => {
        const foundOptions = data?.items;

        const formattedOptions = foundOptions?.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setOptions(formattedOptions);
        setAutoCompleteOpen(true);
      });
    },
    [refetch],
  );

  const debouncedSearchUpdate = useMemo(
    () =>
      debounce((value: string) => {
        fetchAutoCompleteOptions(value);
      }, 1000),
    [fetchAutoCompleteOptions],
  );

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    debouncedSearchUpdate(value);
    setAutoCompleteOpen(false);
  };

  const handleOptionSelect = (value: string) => {
    setSearchValue('');
    setOptions([]);
    setAutoCompleteOpen(false);
    inputRef.current?.blur();

    onLocationSelection(value);
  };

  const onSearchButtonClick = () => {
    if (!isFetching) {
      fetchAutoCompleteOptions(searchValue);
    }
  };

  return (
    <>
      {contextHandler}
      <div className={styles.searchInput} style={style}>
        <label htmlFor="fts">{t('locations.labels.search-location')}:</label>
        <AutoComplete
          options={options}
          onSelect={handleOptionSelect}
          value={searchValue}
          open={autoCompleteOpen}
          style={{ width: '100%' }}
        >
          <Search
            id="fts"
            allowClear
            onChange={handleSearchUpdate}
            onClear={() => setOptions([])}
            onSearch={onSearchButtonClick}
            enterButton={t('common.actions.search')}
            loading={isFetching}
            ref={inputRef}
          />
        </AutoComplete>
      </div>
    </>
  );
};
