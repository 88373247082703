import { useState } from 'react';
import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/Pagination';
import { useLocationsQuery } from 'features/locations/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';

import { LocationsTextSearch } from '../LocationsTextSearch';

import { useLocationsTableColumns } from './useLocationsTableColumns';

const PAGE_SIZE = 15;

export const LocationsTable = () => {
  const columns = useLocationsTableColumns();
  const partnerId = usePartnerId();
  const [page, setPage] = useState(1);
  const [fts, setFts] = useState('');

  const onSearch = (textSearch: string) => {
    setFts(textSearch);
    setPage(1);
  };

  const { data, isLoading } = useLocationsQuery(partnerId, {
    index: page,
    size: PAGE_SIZE,
    fts,
  });
  const locations = data?.items ?? [];

  const hasNextPage = !!locations && locations?.length === PAGE_SIZE;
  const hasPrevPage = page > 1;

  const { t } = useTranslation();

  return (
    <>
      <LocationsTextSearch onSearch={onSearch} isLoading={isLoading} />
      <Table
        columns={columns}
        size="small"
        pagination={false}
        dataSource={locations?.map((item) => ({
          key: item.id,
          id: item.id,
          name: item?.name,
          countryCode: item?.countryCode,
          city: item?.city,
          address: item?.address,
          timeZone: item?.timeZone,
        }))}
      />
      <div style={{ paddingTop: '16px' }}>
        <Pagination
          page={page}
          hasNext={hasNextPage}
          hasPrev={hasPrevPage}
          onChange={(_, index) => setPage(index)}
        >
          <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
        </Pagination>
      </div>
    </>
  );
};
