import { Alert, Button, Form, FormProps, Input, Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useSubpartnersInviteMutation } from 'features/subpartners';

import { InviteFormField, InviteFormProps, PartnerType } from './types';

export const InviteForm = ({ onInvitationSent }: InviteFormProps) => {
  const [form] = Form.useForm();
  const partnerId = usePartnerId();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const { isLoading, mutateAsync, error } = useSubpartnersInviteMutation();

  const onFinish: FormProps<InviteFormField>['onFinish'] = (values) => {
    mutateAsync([partnerId, values])
      .then(() => {
        form.resetFields();
        onInvitationSent();
        message.success(t('subpartners.status.invitation-sent'));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getIsFormValid = () => {
    const fieldsError = form.getFieldsError();
    const allTouched = fieldsError.every((field) =>
      form.isFieldTouched(field.name),
    );

    return fieldsError.every((item) => item.errors.length === 0) && allTouched;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="subpartner-invite-form"
      onFinish={onFinish}
    >
      <Form.Item<InviteFormField>
        name="email"
        label={`${t('subpartners.labels.email')}:`}
        rules={[
          { required: true, message: t('subpartners.rules.email-required') },
          { type: 'email', message: t('subpartners.rules.valid-email') },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item<InviteFormField>
        name="type"
        label={`${t('subpartners.labels.type')}:`}
        rules={[
          { required: true, message: t('subpartners.rules.partners-type') },
        ]}
      >
        <Select
          options={[
            {
              value: 'natural',
              label: <span>{t('subpartners.type.natural')}</span>,
            },
            {
              value: 'legal',
              label: <span>{t('subpartners.type.legal')}</span>,
            },
          ]}
        />
      </Form.Item>
      <Form.Item<InviteFormField>
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => {
          const isNaturalPartner =
            getFieldValue('type') === PartnerType.NATURAL;
          const isLegalPartner = getFieldValue('type') === PartnerType.LEGAL;

          if (isLegalPartner) {
            return (
              <Form.Item<InviteFormField>
                name="name"
                label={`${t('subpartners.labels.name')}:`}
                rules={[
                  {
                    required: true,
                    message: t('subpartners.rules.name-required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          }

          if (isNaturalPartner) {
            return (
              <>
                <Form.Item<InviteFormField>
                  name="firstName"
                  label={`${t('subpartners.labels.first-name')}:`}
                  rules={[
                    {
                      required: true,
                      message: t('subpartners.rules.first-name-required'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item<InviteFormField>
                  name="lastName"
                  label={`${t('subpartners.labels.last-name')}:`}
                  rules={[
                    {
                      required: true,
                      message: t('subpartners.rules.last-name-required'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            );
          }

          return null;
        }}
      </Form.Item>

      <Form.Item shouldUpdate>
        {() => {
          return (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!getIsFormValid() || isLoading}
            >
              {t('common.actions.send')}
            </Button>
          );
        }}
      </Form.Item>
      {error && (
        <Alert
          type="error"
          message={errorT(
            error?.translationKey,
            t('subpartners.status.invitation-failed'),
          )}
        />
      )}
    </Form>
  );
};
