import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchLocations } from '../fetches';
import type { LocationsQueryResponse, LocationsSearchParams } from '../types';

export const USE_LOCATIONS_PARTNER_QUERY_KEY = 'partner.locations';

export function useLocationsQuery(
  partnerId: string,
  params?: LocationsSearchParams,
  options?: UseQueryOptions<
    LocationsQueryResponse,
    Types.HttpError,
    LocationsQueryResponse,
    any[]
  >,
) {
  return useQuery(
    [USE_LOCATIONS_PARTNER_QUERY_KEY, partnerId, params],
    () => fetchLocations(partnerId, params),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
