import { useReducer } from 'react';

import { TagsFilterAction, TagsFilterState } from './types';

const filterInitialState: TagsFilterState = {
  tag: undefined,
  hashTags: [],
};

const tagsFilterReducer = (
  state: TagsFilterState,
  action: TagsFilterAction,
): TagsFilterState => {
  switch (action.type) {
    case 'SELECT_TAG': {
      return {
        ...state,
        tag: action.payload.tag,
      };
    }
    case 'SELECT_HASH_TAGS': {
      return {
        ...state,
        hashTags: action.payload.hashTags,
      };
    }
    default: {
      return state;
    }
  }
};

export const useTagsFilter = () => {
  const [state, dispatch] = useReducer(
    tagsFilterReducer,
    filterInitialState,
  );

  return [state, dispatch] as const;
};
