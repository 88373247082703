import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'components/Router/PrivateRoute';
import { ChargePoints } from 'pages/ChargePoints';
import { ChargePointsSearch } from 'pages/ChargePointsSearch';
import { ChargePointsDetails } from 'pages/ChargePointsDetails';
import { ClientDetail } from 'pages/ClientDetail';
import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import { PartnersTariffsDetails } from 'pages/PartnersTariffs/PartnersTariffsDetails';
import { Profile } from 'pages/Profile/Profile';
import { Register } from 'pages/Register';
import { Tags } from 'pages/Tags/Tags';
import { TariffGroupCreate } from 'pages/TariffGroupCreate';
import { TariffGroupUpdate } from 'pages/TariffGroupUpdate';
import { Tariff } from 'pages/Tariffs';
import { Transactions } from 'pages/Transactions';
import { TransactionDetails } from 'pages/Transactions/TransactionDetails';
import { Layout, LayoutContent } from 'views/Layout';
import { Pricing } from 'pages/Pricing';
import { TariffGroups } from 'pages/Pricing/TariffGroups/';
import { TariffGroupDetails } from 'pages/Pricing/TariffGroupDetails';
import { TariffDetails } from 'pages/Pricing/TariffDetails';
import { TransactionCalculations } from 'pages/Transactions/TransactionCalculations';
import { TagsDetails } from 'pages/TagsDetails';
import { TagsCreate } from 'pages/TagsCreate';
import { BalancingProfiles } from 'pages/BalancingProfiles';
import { BalancingProfilesCreate } from 'pages/BalancingProfilesCreate';
import { BalancingProfileDetails } from 'pages/BalancingProfilesDetails';
import { Clients } from 'pages/Clients';
import { Subpartners } from 'pages/Subpartners';
import { SubpartnerInvitation } from 'pages/SubpartnerInvitation';
import { Reports } from 'pages/Reports';
import { Locations } from 'pages/Locations';
import { LocationCreate } from 'pages/LocationCreate';
import { LocationEdit } from 'pages/LocationEdit';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path={'/'} element={<Dashboard />} />
          <Route element={<LayoutContent />}>
            <Route path={'/partners/profile'} element={<Profile />} />

            {/* CLIENTS */}
            <Route path={'/clients'} element={<Clients />} />
            <Route path={'/clients/:clientId'} element={<ClientDetail />} />

            {/* RFID CARDS */}
            <Route path={'/tags'} element={<Tags />} />
            <Route path={'/tags/:tagId'} element={<TagsDetails />} />
            <Route path={'/tags/create'} element={<TagsCreate />} />

            <Route path={'/tariffs'} element={<Tariff />} />
            <Route
              path={'/partners/:partnerId/tariffs/:groupId/details'}
              element={<PartnersTariffsDetails />}
            />
            <Route
              path={'/partners/:partnerId/tariffs/groups/create'}
              element={<TariffGroupCreate />}
            />
            <Route
              path={'/partners/:partnerId/tariffs/groups/:groupId/update'}
              element={<TariffGroupUpdate />}
            />

            {/* PRICING */}
            <Route path={'pricing'} element={<Pricing />}>
              <Route path={''} element={<TariffGroups />} />
              <Route path={'group/:groupId'} element={<TariffGroupDetails />} />
              <Route
                path={'group/:groupId/tariff/:tariffId'}
                element={<TariffDetails />}
              />
            </Route>

            {/* CHARGE POINTS */}
            <Route path={'/points'} element={<ChargePoints />} />
            <Route path={'/points/add'} element={<ChargePointsSearch />} />
            <Route
              path={'/points/:id/details'}
              element={<ChargePointsDetails />}
            />

            {/* TRANSACTIONS */}
            <Route path={'/transactions'}>
              <Route path={''} element={<Transactions />} />
              <Route
                path={'transaction/:transactionId'}
                element={<TransactionDetails />}
              />
              <Route
                path="transaction/:transactionId/calculations"
                element={<TransactionCalculations />}
              />
            </Route>

            {/* BALANCING PROFILES */}
            <Route
              path={'/balancing-profiles'}
              element={<BalancingProfiles />}
            />
            <Route
              path="/balancing-profiles/create"
              element={<BalancingProfilesCreate />}
            />
            <Route
              path={'/balancing-profiles/:profileId'}
              element={<BalancingProfileDetails />}
            />

            {/* SUBPARTNERS */}
            <Route path={'/sub-partners'} element={<Subpartners />} />

            {/* REPORTS */}
            <Route path={'/reports'} element={<Reports />} />

            {/* LOCATIONS */}
            <Route path={'/locations'} element={<Locations />} />
            <Route path={'/locations/create'} element={<LocationCreate />} />
            <Route path={'/locations/:locationId'} element={<LocationEdit />} />
          </Route>
        </Route>
      </Route>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />
      <Route
        path={'/sub-partners/invites'}
        element={<SubpartnerInvitation />}
      />
    </Routes>
  );
};
