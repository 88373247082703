import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchLocationById } from '../fetches';
import type { Location } from '../types';

export const USE_LOCATION_PARTNER_QUERY_KEY = 'partner.location';

export function useLocationQueryById(
  partnerId: string,
  locationId: string,
  options?: UseQueryOptions<Location, Types.HttpError, Location, string[]>,
) {
  return useQuery(
    [USE_LOCATION_PARTNER_QUERY_KEY, partnerId, locationId],
    () => fetchLocationById(partnerId, locationId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
