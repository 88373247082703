import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { fetchSearchLocations } from '../fetches';
import type { LocationsSearchQueryResponse } from '../types';

export const USE_LOCATIONS_PARTNER_SEARCH_QUERY_KEY =
  'partner.locations.search';

export function useLocationsSearchQuery(
  partnerId: string,
  fts: string,
  options?: UseQueryOptions<
    LocationsSearchQueryResponse,
    Types.HttpError,
    LocationsSearchQueryResponse,
    string[]
  >,
) {
  return useQuery(
    [USE_LOCATIONS_PARTNER_SEARCH_QUERY_KEY, partnerId, fts],
    () => fetchSearchLocations(partnerId, fts),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
