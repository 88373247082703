import { useRef, useState } from 'react';
import { Button, message, Table } from 'antd';
import { uuidv4 } from '@antv/xflow-core';
import { useTranslation } from 'react-i18next';
import {
  USE_LOCATION_PARTNER_QUERY_KEY,
  useLocationUpdateMutation,
} from 'features/locations/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useQueryClient } from '@tanstack/react-query';
import type { LocationPlace } from 'features/locations/types';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import { LocationPlaceModal } from '../LocationPlaceModal';

import type { LocationPlacesProps } from './types';
import styles from './styles.module.css';
import { useLocationPlacesColumns } from './useLocationPlacesColumns';

export const LocationPlaces = ({
  locationPlaces = [],
  locationId,
}: LocationPlacesProps) => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const partnerId = usePartnerId();
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const [selectedPlace, setSelectedPlace] = useState<
    { place: LocationPlace; index: number } | undefined
  >();

  const locationMutation = useLocationUpdateMutation();

  const addNewPlace = () => {
    setModalOpen(true);
  };

  const onCancel = () => {
    setSelectedPlace(undefined);
    setModalOpen(false);
  };

  const mutateLocationPlaces = (newPlaces: LocationPlace[]) => {
    locationMutation
      .mutateAsync([
        partnerId,
        locationId,
        {
          places: newPlaces,
        },
      ])
      .then((data) => {
        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });

        queryClient.setQueryData(
          [USE_LOCATION_PARTNER_QUERY_KEY, partnerId, locationId],
          data,
        );
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: errorT(error?.translationKey, error.message),
        });
      });
  };

  const onLocationPlaceSubmit = (values: LocationPlace, index?: number) => {
    // filter values to remove undefined values
    const valuesToSubmit = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== undefined),
    );

    const newPlaces = [...(locationPlaces ?? [])];

    if (index !== undefined && typeof index === 'number') {
      newPlaces[index] = {
        ...(valuesToSubmit as LocationPlace),
      };
    } else {
      newPlaces.push(valuesToSubmit as LocationPlace);
    }

    mutateLocationPlaces(newPlaces);

    setSelectedPlace(undefined);
    setModalOpen(false);
  };

  const onUpdate = (recordIndex: number) => {
    setSelectedPlace({
      place: locationPlaces[recordIndex],
      index: recordIndex,
    });
    setModalOpen(true);
  };

  const onDelete = (recordIndex: number) => {
    const newPlaces = [...(locationPlaces ?? [])];
    newPlaces.splice(recordIndex, 1);

    setSelectedPlace(undefined);
    setModalOpen(false);

    mutateLocationPlaces(newPlaces);
  };

  const columns = useLocationPlacesColumns({ onUpdate, onDelete });

  return (
    <>
      {contextHolder}
      <Button type="primary" className={styles.addButton} onClick={addNewPlace}>
        {t('locations.actions.add-new-place')}
      </Button>
      <Table
        columns={columns}
        size="small"
        pagination={false}
        dataSource={locationPlaces.map((place) => ({
          key: uuidv4(),
          ...place,
        }))}
      />
      <LocationPlaceModal
        locationId={locationId}
        isOpen={isModalOpen}
        place={selectedPlace}
        onCancel={onCancel}
        onLocationPlaceSubmit={onLocationPlaceSubmit}
      />
    </>
  );
};
