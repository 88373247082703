import { useReducer } from 'react';

import {
  AMOUNT_FROM,
  AMOUNT_TO,
  TransactionStatus,
  TransactionStatusGroup,
  TransactionsFilterAction,
  TransactionsFilterState,
} from './types';
import { mapTransactionGroupToStatus } from './helpers';

const filterInitialState: TransactionsFilterState = {
  statusGroups: {
    [TransactionStatusGroup.ACTIVE]: false,
    [TransactionStatusGroup.SUCCESSFUL]: false,
    [TransactionStatusGroup.CANCELLED]: false,
  },
  statuses: [],
  hideZeroAmounts: false,
  connectorTypes: [],
  deviceTransactionId: undefined,
  chargePointId: undefined,
  [AMOUNT_FROM]: null,
  [AMOUNT_TO]: null,
  startDateFrom: '',
  startDateTo: '',
};

const transactionsFilterReducer = (
  state: TransactionsFilterState,
  action: TransactionsFilterAction,
): TransactionsFilterState => {
  switch (action.type) {
    case 'SELECT_STATUS_GROUP': {
      const { statusGroup, isSelected } = action.payload;

      let newStatusGroups = null;

      /** if `active` status group changed we want to disable all other currently selected status */
      if (statusGroup === TransactionStatusGroup.ACTIVE) {
        newStatusGroups = {
          [TransactionStatusGroup.ACTIVE]: isSelected,
          [TransactionStatusGroup.SUCCESSFUL]: false,
          [TransactionStatusGroup.CANCELLED]: false,
        };
        /** When selecting any other status making sure `active` status is disabled */
      } else {
        newStatusGroups = {
          ...state.statusGroups,
          [TransactionStatusGroup.ACTIVE]: false,
          [statusGroup]: isSelected,
        };
      }

      const relatedStatuses = Object.keys(newStatusGroups).reduce(
        (acc, group) => {
          if (newStatusGroups[group as TransactionStatusGroup]) {
            return [
              ...acc,
              ...mapTransactionGroupToStatus[group as TransactionStatusGroup],
            ];
          }
          return acc;
        },
        [] as TransactionStatus[],
      );

      return {
        ...state,
        statusGroups: newStatusGroups,
        statuses: relatedStatuses,
      };
    }
    case 'SELECT_STATUS': {
      return {
        ...state,
        statuses: action.payload.statuses,
        // reset status groups when selecting specific statuses
        statusGroups: filterInitialState.statusGroups,
      };
    }
    case 'SELECT_DEVICE_TRANSACTION_ID': {
      return {
        ...state,
        deviceTransactionId: action.payload.deviceTransactionId,
      };
    }
    case 'SELECT_CHARGE_POINT_ID': {
      return {
        ...state,
        chargePointId: action.payload.chargePointId,
      };
    }
    case 'HIDE_ZERO_AMOUNTS': {
      return {
        ...state,
        hideZeroAmounts: !state.hideZeroAmounts,
      };
    }
    case 'SELECT_CONNECTOR_TYPE': {
      return {
        ...state,
        connectorTypes: action.payload.connectorTypes,
      };
    }
    case 'UPDATE_AMOUNT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_DATE_RANGE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const useTransactionsFilter = () => {
  const [state, dispatch] = useReducer(
    transactionsFilterReducer,
    filterInitialState,
  );

  return [state, dispatch] as const;
};
