import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LocationImage, LocationPlace } from 'features/locations/types';

import { LocationPlaceImages } from '../LocationPlaces/LocationPlaceImages';

import type { AddLocationPlaceModalProps } from './types';

const defaultFormValues: LocationPlace = {
  name: '',
  address: '',
  description: '',
  url: '',
  distanceM: null,
  lat: null,
  lon: null,
  byFootMin: null,
};

export const LocationPlaceModal = ({
  locationId,
  place,
  isOpen,
  onCancel,
  onLocationPlaceSubmit,
}: AddLocationPlaceModalProps) => {
  const [form] = Form.useForm();
  const locationPlacesImagesRef = useRef<{ resetState: () => void }>(null);
  const [tempPlaceImages, setTempPlaceImages] = useState<
    Required<LocationPlace>['images']
  >([]);

  const { t } = useTranslation();

  const handleAddLocationPlace = (values: LocationPlace) => {
    onLocationPlaceSubmit(
      {
        ...values,
        images: tempPlaceImages,
      },
      place?.index,
    );

    form.resetFields();
    locationPlacesImagesRef.current?.resetState();
  };

  const onModalCancel = () => {
    onCancel();
    form.resetFields();
    locationPlacesImagesRef.current?.resetState();
  };

  useEffect(() => {
    const formValues = { ...(place ? place.place : defaultFormValues) };
    form.setFieldsValue(formValues);
    setTempPlaceImages(place?.place.images ? [...place?.place.images] : []);
  }, [form, place]);

  const handleImageDelete = (fileCustomProps?: {
    originalFileId?: string;
    originalFileUrl?: string;
  }) => {
    console.log(fileCustomProps);
    setTempPlaceImages((current) => {
      return current.filter(
        (image) =>
          fileCustomProps?.originalFileId !== image.fileId ||
          fileCustomProps.originalFileUrl !== image.url,
      );
    });
  };

  const handleImageUpload = (image: LocationImage) => {
    setTempPlaceImages((current) => [...current, image]);
  };

  const title = place
    ? t('locations.titles.edit-location-place')
    : t('locations.titles.add-location-place');

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={onModalCancel}
      footer={[
        <Button key={'cancel'} type={'default'} onClick={onModalCancel}>
          {t('common.actions.cancel')}
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          htmlType={'submit'}
          form={'location-place-form'}
        >
          {t('common.actions.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id={'location-place-form'}
        layout={'vertical'}
        onFinish={handleAddLocationPlace}
      >
        <Form.Item
          name={'name'}
          label={t('locations.labels.name')}
          rules={[{ required: true, message: t('locations.rules.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'address'}
          label={t('locations.labels.address')}
          rules={[{ required: true, message: t('locations.rules.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'description'}
          label={t('locations.labels.description')}
          rules={[{ required: true, message: t('locations.rules.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'url'}
          label={t('locations.labels.url')}
          rules={[
            { required: true, message: t('locations.rules.required') },
            { type: 'url', message: t('locations.rules.invalid-url') },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={'distanceM'}
              label={t('locations.labels.distance-meters')}
              rules={[
                { required: true, message: t('locations.rules.required') },
              ]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>

            <Form.Item name={'lat'} label={t('locations.labels.latitude')}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'byFootMin'}
              label={t('locations.labels.minutes-by-foot')}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>

            <Form.Item name={'lon'} label={t('locations.labels.longitude')}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={t('locations.labels.images')}>
          <LocationPlaceImages
            locationId={locationId}
            placeImages={tempPlaceImages}
            onImageDelete={handleImageDelete}
            onImageUpload={handleImageUpload}
            ref={locationPlacesImagesRef}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
