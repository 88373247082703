import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Connector } from 'core/types/Connector';
import { ConnectorLabel } from 'components/view/ConnectorLabel';
import { ColumnsType } from 'antd/es/table';

import type { LocationChargePointsProps } from './types';

export const LocationChargePoints = ({
  chargePoints,
}: LocationChargePointsProps) => {
  const { t } = useTranslation();

  const columns: ColumnsType<Record<string, any>> = [
    {
      title: t('chargepoints.columns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, item: any) => {
        return <Link to={`/points/${item.key}/details`}>{text}</Link>;
      },
    },
    {
      title: t('chargepoints.columns.ocpp-version'),
      dataIndex: 'ocppVersion',
      key: 'ocppVersion',
      fixed: 'left',
    },
    {
      title: t('chargepoints.columns.connectors'),
      dataIndex: 'connectors',
      key: 'connectors',
      render: (connectorsDict?: Record<number, Connector>) => {
        const connectors = connectorsDict && Object.values(connectorsDict);
        return connectors && connectors.length > 0
          ? connectors.map((item, idx) => (
              <ConnectorLabel
                key={idx}
                type={item.type}
                status={item.availability}
              />
            ))
          : 'None';
      },
    },
  ];

  return (
    <Table
      columns={columns}
      size="small"
      pagination={false}
      dataSource={chargePoints.map((item) => ({
        key: item.id,
        name: item.name,
        ocppVersion: item?.ocppVersion ?? '-',
      }))}
      scroll={{ x: 1300 }}
    />
  );
};
