import { Typography } from 'antd';
import { Padding } from 'components/Padding';
import { LocationCreateForm } from 'pages/Locations/components/LocationCreateForm';
import { useTranslation } from 'react-i18next';

export const LocationCreate = () => {
  const { t } = useTranslation();

  return (
    <>
      <Padding isHorizontal bottomBorder>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t('locations.titles.create-location')}
        </Typography.Title>
      </Padding>
      <Padding>
        <LocationCreateForm />
      </Padding>
    </>
  );
};
